import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"icons-container"}},[_c('div',{staticClass:"d-flex align-center flex-wrap"},_vm._l((_vm.iconsList),function(item){return _c(VTooltip,{key:item.icon,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VCard,_vm._g(_vm._b({staticClass:"icons-card mb-6 me-6"},'v-card',attrs,false),on),[_c(VCardText,{staticClass:"py-3 px-4"},[_c(VIcon,{attrs:{"size":"30"}},[_vm._v(" "+_vm._s(item.icon)+" ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])])}),1),_c('div',{staticClass:"text-center"},[_c(VBtn,{attrs:{"href":"https://materialdesignicons.com/","target":"_blank","rel":"nofollow","color":"primary"}},[_vm._v(" View All Material Design Icons ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }